export const fields = {
  airport: {
    label: 'Select Airport*',
    rules: 'required',
  },
  associateAirport: {
    label: 'Associate Airport*',
    rules: 'required',
  },
  airportHoursType: {
    label: 'Hours Types*',
    rules: 'required',
  },
  information: {
    label: 'Time Zone Information',
    rules: 'string',
  },
};
