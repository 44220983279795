export enum AIRPORT_FILTERS {
  ALL = 'All',
  ICAO = 'ICAO',
  UWACode = 'UWA',
  IATA = 'IATA',
  FAA_CODE = 'FAA Code',
  REGIONAL_CODE = 'Regional Code',
  SOURCE_LOCATION_ID = 'Source Location ID',
  NAME = 'Name',
  CAPPS_AIRPORT_NAME = 'Capps Airport Name',
  CITY = 'City',
  STATE = 'State',
  COUNTRY = 'Country',
}
