export enum AIRPORT_MAPPING_FILTERS {
  ICAO = 'ICAO',
  NAVBLUE = 'NAVBLUE',
  APG = 'APG',
}

export enum AIRPORT_MAPPING_BETA_FILTERS {
  ICAO = 'ICAO',
  UWA = 'UWA Airport Code',
  Regional = 'Regional Airport Code',
  FAA = 'FAA',
  NAVBLUE = 'NAVBLUE',
  APG = 'APG',
}