import { Observable, of } from 'rxjs';
import { IAPIAirportCustomGeneralRequest } from '../Interfaces';
import { AirportCustomDetailStore } from '../Stores';
import { AirportCustomGeneralModel, IntlCustomsDetailsModel } from '../Models';

export class AirportCustomDetailStoreMock extends AirportCustomDetailStore {
  public upsertGeneral(request: IAPIAirportCustomGeneralRequest): Observable<AirportCustomGeneralModel> {
    return of(new AirportCustomGeneralModel());
  }

  public upsertIntlCustomsInfo(request: IntlCustomsDetailsModel): Observable<IntlCustomsDetailsModel> {
    return of(new IntlCustomsDetailsModel());
  }
}
