export const fields = {
  rampSideAccess: {
    label: 'Ramp Side Access',
  },
  rampSideAccess3rdPartyVendors: {
    label: 'Ramp Side Access 3rd Party Vendors',
    value: [],
  },
  rampSideAccess3rdParty: {
    label: 'Ramp Side Access 3rd Party',
  },
  rampSideAccessNonOpsDuringStay: {
    label: 'Ramp Side Access Non Ops During Stay',
    rules: 'string|max:300',
  },
  airportFencing: {
    label: 'Airport Fencing ',
  },
  airportPolice: {
    label: 'Airport Police',
  },
  crewScreening: {
    label: 'Crew Screening',
  },
  passengerScreening: {
    label: 'Passenger Screening',
  },
  baggageScreening: {
    label: 'Baggage Screening',
  },
  securityPatrols: {
    label: 'Security Patrols',
  },
  privateSecurityAllowed: {
    label: 'Private Security Allowed',
  },
  securityOrCompanyIdRqrdForCrew: {
    label: 'Security or Company ID Required for Crew',
  },
  uniformRequiredForCrew: {
    label: 'Uniform Required for Crew',
  },
  parkingAreaSecurityMeasures: {
    label: 'Parking Area Security Measures',
    value: [],
  },
  gaParkingSecurityMeasures: {
    label: 'GA Parking Security Measures',
    value: [],
  },
  airportSecurityMeasures: {
    label: 'Airport Security Measures',
    value: [],
  },
  airportSecurityViaAirportAuthorityOnly: {
    label: 'Airport Security via Airport Authority Only',
  },
  airportSecurity24Hours: {
    label: 'Airport Security 24 Hours',
  },
  securityNotes: {
    label: 'Security Notes',
    rules: 'string|max:300',
  },
  recommendedSecurityServices: {
    label: 'Recommended Security Services',
    value: [],
  },
};
