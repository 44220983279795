export enum AIRPORT_CODE_TYPES {
  ICAO_CODE = 'ICAO Code',
  UWA_CODE = 'UWA Code',
  REGIONAL_CODE = 'Regional Code',
}

export enum AIRPORT_CODE_FIELDS {
  ICAO_CODE = 'icaoCode',
  UWA_CODE = 'uwaAirportCode',
  REGIONAL_CODE = 'regionalAirportCode',
}
