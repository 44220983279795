/* istanbul ignore next */
export const fields = {
  navBlueCode: {
    label: 'NavBlue Code',
    rules: 'string|between:3,4',
  },
  apgCode: {
    label: 'APG Code',
    rules: 'string|between:3,4',
  },
  fplzzzz: {
    label: 'FPL ZZZZ',
  },
  fplzzzzItem18: {
    label: 'FPL ZZZZ Item 18',
    rules: 'string|between:1,50',
  },
  isACDMAirport: {
    label: 'A-CDM Airport',
  },
  isEuroControlFPLACDMAirport: {
    label: 'EuroControl FPL A-CDM Airport',
  },
  isVFRAirport: {
    label: 'VFR Airport',
  },
  isCompositeFlightPlanRequired: {
    label: 'Composite Flight Plan Required',
  },
  appliedDestAltTOFs: {
    label: 'Destination Alternate - Type of Flight',
    value:[]
  },
  fpAirspace: {
    label: 'FP Airspace',
  },
};
