import AddIcon from '@material-ui/icons/AddCircleOutline';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { GridPagination, GRID_ACTIONS, IAPIGridRequest, UIStore, Utilities, ViewPermission } from '@wings-shared/core';
import { agGridUtilities, useAgGrid, useGridState } from '@wings-shared/custom-ag-grid';
import { SearchHeaderV3, useSearchHeader } from '@wings-shared/form-controls';
import { CustomLinkButton, SidebarStore } from '@wings-shared/layout';
import { AirportModel, AuditHistory, baseApiPath } from '@wings/shared';
import { GridOptions, FilterChangedEvent } from 'ag-grid-community';
import { inject, observer } from 'mobx-react';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  AirportHoursModel,
  AirportHoursStore,
  AirportModuleSecurity,
  AirportSettingsStore,
  airportSidebarOptions,
  AIRPORT_AUDIT_MODULES,
  AIRPORT_HOUR_FILTERS,
} from '../Shared';
import { airportHoursGridFilters } from './fields';
import { useUnsubscribe } from '@wings-shared/hooks';
import { CommonAirportHoursGridV2 } from './Components';

interface Props {
  airportHoursStore?: AirportHoursStore;
  airportSettingsStore?: AirportSettingsStore;
  sidebarStore?: typeof SidebarStore;
}

const AirportHours: FC<Props> = ({ airportHoursStore, airportSettingsStore, sidebarStore }: Props) => {
  const gridState = useGridState();
  const searchHeader = useSearchHeader();
  const agGrid = useAgGrid<AIRPORT_HOUR_FILTERS, AirportHoursModel>(airportHoursGridFilters, gridState);
  const unsubscribe = useUnsubscribe();
  const _airportHoursStore = airportHoursStore as AirportHoursStore;
  const _airportSettingsStore = airportSettingsStore as AirportSettingsStore;
  const _sidebarStore = sidebarStore as typeof SidebarStore;
  const [ entityOptions, setEntityOptions ] = useState([]);
  const isICAOFilter = Utilities.isEqual(searchHeader.searchType, AIRPORT_HOUR_FILTERS.ICAO);

  /* istanbul ignore next */
  // Load Data on Mount
  useEffect(() => {
    _sidebarStore?.setNavLinks(airportSidebarOptions(true), '/airports');
    agGrid.filtersApi.onAdvanceFilterChange$.subscribe(() => loadAirportHours());
    searchHeader.restoreSearchFilters(gridState, () => loadAirportHours());
  }, []);

  /* istanbul ignore next */
  const _searchFilters = (): IAPIGridRequest => {
    const _selectedOption = searchHeader.searchType;
    const searchChips: any = searchHeader.getFilters().chipValue?.valueOf();
    const _searchValue = isICAOFilter
      ? searchHeader.searchValue
      : searchChips.map(x =>
        Utilities.isEqual(_selectedOption, AIRPORT_HOUR_FILTERS.AIRPORT_CODE) ? x.value : x.label
      );

    if (!_searchValue) {
      return {};
    }
    return {
      ...agGrid.filtersApi.getSearchFilters(_searchValue, _selectedOption),
    };
  };

  // load dropdown options for search header select control
  /* istanbul ignore next */
  const onSearchEntity = searchValue => {
    if (searchHeader.searchType === AIRPORT_HOUR_FILTERS.ICAO) {
      loadAirportHours({ pageNumber: 1 });
    }

    let observableOf;
    switch (searchHeader.searchType) {
      case AIRPORT_HOUR_FILTERS.AIRPORT_HOURS_TYPE:
        observableOf = _airportSettingsStore.loadAirportHourTypes();
        break;

      case AIRPORT_HOUR_FILTERS.AIRPORT_HOURS_SUB_TYPE:
        observableOf = _airportSettingsStore.loadAirportHourSubTypes();
        break;

      case AIRPORT_HOUR_FILTERS.AIRPORT_CODE:
        const _searchValue = searchValue;
        if (!_searchValue) {
          setEntityOptions([]);
          return;
        }
        observableOf = _airportHoursStore.searchWingsAirportsByCode(_searchValue, {
          excludeRetail: true,
          includeInactive: true,
        });
        break;
      default:
        break;
    }
    if (observableOf) {
      UIStore.setPageLoader(true);
      observableOf
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe(response => setEntityOptions(response));
    }
  };

  /* istanbul ignore next */
  const loadAirportHours = (pageRequest?: IAPIGridRequest): void => {
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      ...pageRequest,
      ..._searchFilters(),
      ...agGrid.filtersApi.gridSortFilters(),
      ...agGrid.filtersApi.getAdvancedSearchFilters(),
    };

    UIStore.setPageLoader(true);
    _airportHoursStore
      .loadAirportHours(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: response => {
          gridState.setGridData(response.results);
          gridState.setPagination(new GridPagination({ ...response }));
          agGrid.filtersApi.gridAdvancedSearchFilterApplied();
        },
      });
  };

  const gridActions = (gridAction: GRID_ACTIONS, rowIndex: number): void => {
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.AUDIT:
        const model: AirportHoursModel = agGrid._getTableItem(rowIndex);
        ModalStore.open(
          <AuditHistory
            title={model.airport?.label || model.icao}
            entityId={model.id}
            entityType={AIRPORT_AUDIT_MODULES.AIRPORT_HOURS}
            baseUrl={baseApiPath.airports}
          />
        );
        break;
      case GRID_ACTIONS.DETAILS:
      case GRID_ACTIONS.EDIT:
      case GRID_ACTIONS.VIEW:
        searchHeader.saveSearchFilters(gridState);
        break;
    }
  };

  /* istanbul ignore next */
  const gridOptions = (): GridOptions => {
    const baseOptions: Partial<GridOptions> = agGrid.gridOptionsBase({
      context: { onDropDownChange: () => gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi)) },
      columnDefs: [],
      isEditable: false,
      gridActionProps: {
        tooltip: 'Airport Hours',
        getDisabledState: () => gridState.hasError,
      },
    });

    return {
      ...baseOptions,
      pagination: false,
      isExternalFilterPresent: () => false,
      onFilterChanged: (events: FilterChangedEvent) => {
        Array.from(gridState.columFilters).length === 0 && loadAirportHours();
      },
      onSortChanged: e => {
        agGrid.filtersApi.onSortChanged(e);
        loadAirportHours({ pageNumber: 1 });
      },
    };
  };

  const rightContent = (): ReactNode => {
    return (
      <ViewPermission hasPermission={AirportModuleSecurity.isEditable}>
        <CustomLinkButton
          variant="contained"
          startIcon={<AddIcon />}
          to="/airports/airport-hours/new"
          title="Add Hours"
        />
      </ViewPermission>
    );
  };

  return (
    <>
      <SearchHeaderV3
        useSearchHeader={searchHeader}
        onExpandCollapse={agGrid.autoSizeColumns}
        selectInputs={[ agGridUtilities.createSelectOption(AIRPORT_HOUR_FILTERS, AIRPORT_HOUR_FILTERS.ICAO) ]}
        isChipInputControl={!isICAOFilter}
        chipInputProps={{
          options: entityOptions,
          allowOnlySingleSelect: false,
          getChipLabel: option => (option as AirportModel)?.displayCode || option.label,
          getOptionLabel: option => (option as AirportModel)?.displayCode || option.label,
          onFocus: () => onSearchEntity(''),
        }}
        onResetFilterClick={() => {
          agGrid.cancelEditing(0);
          agGrid.filtersApi.resetColumnFilters();
        }}
        rightContent={rightContent}
        onSearch={onSearchEntity}
        onFiltersChanged={() => loadAirportHours({ pageNumber: 1 })}
        onSelectionChange={(fieldKey, updatedValue) => {
          setEntityOptions([]);
          searchHeader.onSelectionChange(fieldKey, updatedValue);
        }}
        isLoading={UIStore.pageLoading}
        disableControls={Boolean(Array.from(gridState.columFilters).length)}
      />
      <CommonAirportHoursGridV2
        rowData={gridState.data}
        auditFields={agGrid.auditFields(gridState.isRowEditing)}
        gridOptions={gridOptions()}
        serverPagination={true}
        paginationData={gridState.pagination}
        onPaginationChange={loadAirportHours}
        onAction={gridActions}
        nameSearchFilterParams={agGrid.filtersApi.getAdvanceFilterParams}
      />
    </>
  );
};

export default inject('airportHoursStore', 'airportSettingsStore', 'sidebarStore')(observer(AirportHours));
